import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { formatDate, formatDateOnly } from "../../../Functions/formatDate";
import { useSelector } from "react-redux";

export default function DoorOpenGraph(props) {
  const [openHistory, setOpenHistory] = React.useState([]);

  let colours = ["#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#d8c4b1"];

  const theme = useTheme();

  const { val = ["door_open"], title = "Door Open History" } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);
  useEffect(() => {
    if (deviceHistory.length === 0) {
      return;
    }

    try {
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;

        //create a new object with the date and door open status for each val in the array

        let newObject = {
          Date: time_created,
        };

        val.forEach((v) => {
          newObject[v] = jsonData[v];
        });

        tempHistory.push(newObject);
      });
      setOpenHistory(tempHistory);
    } catch (error) {}
  }, [deviceHistory, val]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title={title} />
      <CardContent>
        <ResponsiveContainer aspect={!isMobile ? 10 : 2}>
          <AreaChart
            data={openHistory}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              {/* create a gradient for the area for each val in the array */}
              {val.map((v, index) => {
                return (
                  <linearGradient
                    key={index}
                    id={v}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="5%"
                      stopColor={colours[index]}
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor={colours[index]}
                      stopOpacity={0}
                    />
                  </linearGradient>
                );
              })}
            </defs>
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis
              type={"category"}
              dataKey={val[0]}
              tickFormatter={(value) => {
                return value === 1 ? "Open" : "Closed";
              }}
            />

            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              formatter={(value, name) => {
                let v = value === 1 ? "Open" : "Closed";
                let n = name === "door_open" ? "Door 1" : "Door 2";
                return [v, n];
              }}
              labelFormatter={(value) => {
                return formatDate(value);
              }}
            />
            {
              //loop through the val array and create an Area for each
              val.map((v, index) => {
                return (
                  <Area
                    key={index}
                    yAxisId={0}
                    dataKey={v}
                    stroke={theme.palette.primary.main}
                    animationDuration={300}
                    fill={`url(#${v})`}
                    strokeWidth={2}
                  />
                );
              })
            }
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
