import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import addDashboard from "../../Functions/addDashboard";

export default function DashboardAddDialog(props) {
  const { t } = useTranslation("common");

  const { open, handleClose } = props;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [dashboardDevices, setDashboardDevices] = useState([]);
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const devices = useSelector((state) => state.devices);

  const handleAdd = () => {
    if (name === "") {
      updateSnackbarMessage("Error: Name is required");
      return;
    }

    let result = addDashboard(
      name,
      description,
      dashboardDevices,
      selectedOrganisation.id
    );

    if (result) {
      updateSnackbarMessage("Success: Dashboard added");

      //wait 1 second and close dialog
      setTimeout(() => {
        handleClose();
      }, 1000);
    } else {
      updateSnackbarMessage("Error: Dashboard not added");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>{t("dashboards.add")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={t("common.name")}
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        {
          //description
        }
        <TextField
          margin="dense"
          id="description"
          label={t("common.description")}
          type="text"
          fullWidth
          multiline={true}
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        {
          //autocomplete multi select for devices
        }

        <Typography variant="h6" sx={{ my: 1 }}>
          {t("dashboards.select_devices")}
        </Typography>

        <Autocomplete
          multiple
          id="tags-standard"
          options={devices}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select Devices"
            />
          )}
          onChange={(event, value) => {
            setDashboardDevices(value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t("common.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleAdd}>
          {t("common.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
