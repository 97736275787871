const initialState = [];

function assetHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case "assetHistory/init":
      return action.payload;
    case "assetHistory/add":
      return [...state.value, action.payload];
    case "assetHistory/remove":
      return state.filter((asset) => asset.id !== action.payload);
    case "assetHistory/update":
      return action.payload;
    default:
      return state;
  }
}

export default assetHistoryReducer;
