import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
//mui
import { Grid, Tooltip, Button, useTheme, Stack } from "@mui/material";
import { Add } from "@mui/icons-material";

//functions
import { useParams } from "react-router-dom";
import fetchOrganisationMembers from "../Functions/fetchOrganisationMembers";
import OrganisationMemberCard from "../components/Cards/organisationMember";
import OrganisationAddMemberDialog from "../components/Dialogs/organisationAddMember";
import fetchData from "../API/Fetch";
import PageTitle from "../components/PageTitle";
import setTitle from "../Functions/setTitle";
import { useSelector } from "react-redux";

function OrganisationMembers() {
  const [organisationMembers, setOrganisationMembers] = useState({});
  const [members, setMembers] = useState([]);
  const params = useParams();

  const theme = useTheme();

  const [showAddMemberDialog, setShowAddMemberDialog] = useState(false);

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setTitle("Organisation Members", selectedOrganisation);

      fetchData("/organisation/" + params.id).then(
        (organisationMemberResult) => {
          setOrganisationMembers(organisationMemberResult);
          fetchOrganisationMembers(params.id).then((members) => {
            setMembers(members);
          });
        }
      );
    }
    return () => (mounted = false);
  }, [params.id, selectedOrganisation]);

  const refreshMembers = () => {
    fetchOrganisationMembers(params.id).then((members) => {
      setMembers(members);
    });
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Stack direction="row" justifyContent="flex-end" sx={{ mt: 1 }}>
            <PageTitle
              title={
                organisationMembers
                  ? organisationMembers.name
                    ? organisationMembers.name + " Members"
                    : ""
                  : ""
              }
              theme={theme}
            />

            <Tooltip title="Add a member">
              <Button
                variant="outlined"
                color={theme.palette.mode === "dark" ? "primary" : "secondary"}
                onClick={() => {
                  setShowAddMemberDialog(true);
                }}
              >
                <Add />
              </Button>
            </Tooltip>
          </Stack>
        </Grid>

        {members &&
          members.length > 0 &&
          members.map((member) => (
            <OrganisationMemberCard
              key={member.id}
              member={member}
              organisation={organisationMembers}
              refreshMembers={refreshMembers}
            />
          ))}
      </Grid>

      <OrganisationAddMemberDialog
        organisationId={params.id}
        open={showAddMemberDialog}
        onClose={() => {
          refreshMembers();
          setShowAddMemberDialog(false);
        }}
      />
    </>
  );
}

export default withAuthenticationRequired(OrganisationMembers);
