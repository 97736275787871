import React from "react";
import { Grid } from "@mui/material";
import LastAlert from "../Cards/LastAlert";
import LastSeen from "../Cards/LastSeen";
import FrameList from "../FrameList";
import DeviceMetric from "../Cards/DeviceMetric";
import GraphBase from "../Graphs/GraphBase";

export default function MokoSmartLW005(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={isShare ? 12 : 6} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={6} sx={{ mb: 1 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <DeviceMetric
            value="plug_state"
            title="Plug State"
            icon="plug"
            iconSize="6x"
            truefalse={true}
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <DeviceMetric
            value="total_energy"
            title="Total Energy"
            icon="battery-full"
            iconSize="6x"
            trimValue={true}
            valueToTrim={"KWH"}
            unit={"KWH"}
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <DeviceMetric
            value="energy_of_last_hour"
            title="Total Energy Last Hour"
            icon="battery-full"
            iconSize="6x"
            trimValue={true}
            valueToTrim={"KWH"}
            unit={"KWH"}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <DeviceMetric
            value="instantaneous_active_power"
            title="Instantaneous Active Power"
            icon="bolt"
            iconSize="6x"
            trimValue={true}
            valueToTrim={"W"}
            unit={"W"}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <DeviceMetric
            value="instantaneous_current"
            title="Instantaneous Current"
            icon="bolt"
            iconSize="6x"
            trimValue={true}
            valueToTrim={"A"}
            unit={"A"}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <DeviceMetric
            value="instantaneous_power_factor"
            title="Instantaneous Power Factor"
            icon="percentage"
            iconSize="6x"
            trimValue={true}
            valueToTrim={"%"}
            unit={"%"}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <DeviceMetric
            value="instantaneous_voltage"
            title="Instantaneous Voltage"
            icon="bolt"
            iconSize="6x"
            trimValue={true}
            valueToTrim={"V"}
            unit={"V"}
          />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <GraphBase
            value="total_energy"
            title="Total Energy History"
            unit="KWH"
            trimValue={true}
            trimNumber={3}
          />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <GraphBase
            value="plug_state"
            title="Plug State History"
            truefalse={true}
            unit=""
            trueV={"On"}
            falseV={"Off"}
          />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <GraphBase
            value="instantaneous_active_power"
            title="Instantaneous Active Power"
            unit="W"
            trimValue={true}
          />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <GraphBase
            value="instantaneous_power_factor"
            title="Instantaneous Power Factor"
            unit="%"
            min={0}
            max={100}
            trimValue={true}
          />
        </Grid>

        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <FrameList isShare={isShare} />
        </Grid>
      </Grid>
    </>
  );
}
