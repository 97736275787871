const initialState = [];

function assetsReducer(state = initialState, action) {
  switch (action.type) {
    case "assets/init":
      return action.payload;
    case "assets/add":
      return [...state.value, action.payload];
    case "assets/remove":
      return state.filter((asset) => asset.id !== action.payload);
    case "assets/update":
      return action.payload;
    default:
      return state;
  }
}

export default assetsReducer;
