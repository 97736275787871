import { styled, Switch, Typography } from "@mui/material";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { postData } from "../../API/Post";
import { useEffect, useState } from "react";
import WidgetCard from "./WidgetCard";

export default function SwitchWidget(props) {
  const { widget, refreshDashboard, editMode, isShare, dashboardId, devices } =
    props;
  const [switchState, setSwitchState] = useState(false);

  let deviceData = null;
  let parsedData = null;

  let date = "N/A";

  const {
    statusField,
    Field,
    actionTrue,
    actionFalse,
    deviceID,
    Title,
    actionFalseport,
    actionTrueport,
    size,
  } = JSON.parse(widget.field);

  let scaleSize = 1;

  //size will be 1x - 10x, remove the x and convert to a number
  if (size) {
    scaleSize = parseInt(size.replace("x", ""));
  }

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 68 * scaleSize,
    height: 34 * scaleSize,
    padding: 7 * scaleSize,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(" + 6 * scaleSize + "px)",
      "&.Mui-checked": {
        transform: "translateX(" + 30 * scaleSize + "px)",
      },
    },
    "& .MuiSwitch-thumb": {
      width: 32 * scaleSize,
      height: 32 * scaleSize,
    },
    "& .MuiSwitch-track": {
      borderRadius: (20 * scaleSize) / 2,
    },
  }));

  const device = devices.find(
    (device) => parseInt(device.id) === parseInt(statusField)
  );

  let lora = true;

  if (device) {
    date = device.time_updated;

    if (device && device.device_brand && device.device_brand.non_lora) {
      lora = false;
    }

    deviceData = device.last_data;

    parsedData = JSON.parse(deviceData);
  }

  async function sendCommand(command, port) {
    if (!device) return;

    if (lora) {
      await postData(`/device/${device.id}/downlink`, {
        command: command,
        port: port,
      });
    } else {
      await postData(`/device/${device.id}/downlink/cat1m`, {
        device_id: deviceID,
        command: command,
      });
    }
  }

  const handleSwitch = () => {
    if (switchState) {
      if (!actionTrue) return;

      sendCommand(actionTrue, actionTrueport);

      updateSnackbarMessage("Turning Off");
    } else {
      if (!actionFalse) return;
      sendCommand(actionFalse, actionFalseport);
      updateSnackbarMessage("Turning On");
    }
    setSwitchState(!switchState);
  };

  useEffect(() => {
    if (!deviceData || !Field || !parsedData) return;

    setSwitchState(
      parsedData[Field] === "true" ||
        parsedData[Field] === true ||
        parsedData[Field] === 1 ||
        parsedData[Field] === "1"
        ? true
        : false
    );
  }, [deviceData, Field, parsedData, widget]);

  return (
    <WidgetCard
      widget={widget}
      refreshDashboard={refreshDashboard}
      editMode={editMode}
      isShare={isShare}
      dashboardId={dashboardId}
      title={Title}
      date={date}
    >
      <Typography
        sx={{
          fontSize: scaleSize + "rem",
        }}
        variant="h6"
      >
        {switchState ? "On" : "Off"}
      </Typography>
      <CustomSwitch checked={switchState} onChange={handleSwitch} />
    </WidgetCard>
  );
}
