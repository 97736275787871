import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { postData } from "../../API/Post";
import fetchAssets from "../../Functions/fetchAssets";
import { useSelector } from "react-redux";

export default function AssetMetricAddDialog(props) {
  const { open, handleClose, asset } = props;

  const { handleSubmit, control, register, unregister, watch } = useForm();

  const [deviceId, setDeviceId] = useState(null);
  const [deviceField, setDeviceField] = useState(null);

  const devices = useSelector((state) => state.devices);

  const deviceTrackers = devices.filter(
    (device) => device.device_type in [1, 4, 7, 14, 15, 16, 41, 44]
  );

  const [deviceFields, setDeviceFields] = useState([]);

  const watchMetricType = watch("metric_type");

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const { t } = useTranslation("common");

  const onSubmit = async (data) => {
    const { label, metric_type } = data;

    let device = devices.find(
      (device) => parseInt(device.id) === parseInt(deviceId)
    );

    let last_data = device.last_data;
    //parse json and get keys
    let parsedData = JSON.parse(last_data);
    let value = parsedData[deviceField];

    //if metric type is location, get the geocode, latitude and longitude and create a json serialized string
    if (parseInt(metric_type) === 3) {
      let geocode = parsedData["geocode"];
      let latitude = parsedData["latitude"];
      let longitude = parsedData["longitude"];
      value = JSON.stringify({
        geocode: geocode,
        latitude: latitude,
        longitude: longitude,
      });
    }

    let jsonData = {
      asset_id: asset.id,
      label: label,
      metric_type_id: metric_type,
      device_id: deviceId,
      device_field: deviceField,
      value: value,
    };

    try {
      await postData("/asset/metric", jsonData);
      updateSnackbarMessage(t("assets.asset_metric_created"));
    } catch (error) {
      updateSnackbarMessage("Error: " + error.message);
      console.log(error);
    }
    await fetchAssets(selectedOrganisation.id);
    handleClose();
  };

  const validationLabels = {
    name: {
      required: t("assets.name_required"),
    },
    metric_type: {
      required: t("assets.metric_type_required"),
    },
    device: {
      required: t("assets.device_required"),
    },
    device_field: {
      required: t("assets.device_field_required"),
    },
  };

  const metricTypes = [
    {
      id: 1,
      name: "Temperature",
    },
    {
      id: 2,
      name: "Humidity",
    },
    {
      id: 3,
      name: "Location",
    },
    {
      id: 4,
      name: "String",
    },
    {
      id: 5,
      name: "Boolean",
    },
    {
      id: 6,
      name: "Number",
    },
  ];

  useEffect(() => {
    //if watchMetricType is not location and device is selected, get the fields for the device
    if (watchMetricType !== 3) {
      register("device_field");
      let device = devices.find(
        (device) => parseInt(device.id) === parseInt(deviceId)
      );

      if (device) {
        let last_data = device.last_data;
        //parse json and get keys
        let keys = Object.keys(JSON.parse(last_data));
        let fields = [];
        keys.forEach((key) => {
          fields.push({ id: key, name: key });
        });

        //remove fields with keys of geocode, latitude, longitude, accuracy, dont_log_frame, hotspots

        fields = fields.filter(
          (field) =>
            ![
              "geocode",
              "latitude",
              "longitude",
              "accuracy",
              "dont_log_frame",
              "hotspots",
            ].includes(field.id)
        );

        setDeviceFields(fields);
      }
    } else {
      unregister("device_field");
    }
  }, [watchMetricType, deviceId, devices, register, unregister]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t("assets.add_metric")}</DialogTitle>
        <DialogContent>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Controller
              name="label"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue="New Metric"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={error !== undefined}
                  helperText={error ? validationLabels.name[error.type] : ""}
                  label={t("common.label")}
                  variant="outlined"
                  fullWidth
                />
              )}
              sx={{ mt: 4 }}
            />
            <Controller
              name="metric_type"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  fullWidth
                  label={t("assets.metric_type")}
                  variant="outlined"
                  error={error !== undefined}
                  helperText={
                    error ? validationLabels.metric_type[error.type] : ""
                  }
                  sx={{ mt: 4 }}
                >
                  {metricTypes.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="device"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  select
                  fullWidth
                  label={t("assets.device")}
                  variant="outlined"
                  onChange={(e) => setDeviceId(e.target.value)}
                  error={error !== undefined}
                  helperText={error ? validationLabels.device[error.type] : ""}
                  sx={{ mt: 4 }}
                >
                  {watchMetricType !== 3
                    ? devices.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))
                    : deviceTrackers.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                </TextField>
              )}
            />
            {watchMetricType !== 3 ? (
              //if its not location, show device fields
              <Controller
                name="device_field"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    label={t("assets.device_field")}
                    variant="outlined"
                    error={error !== undefined}
                    onChange={(e) => setDeviceField(e.target.value)}
                    helperText={
                      error ? validationLabels.device_field[error.type] : ""
                    }
                    sx={{ mt: 4 }}
                  >
                    {deviceFields.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            {t("common.add")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
