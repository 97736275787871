import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader } from "@mui/material";
import React, { useMemo } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useSelector } from "react-redux";
import { parseDeviceHistory } from "../../../Functions/parseDeviceHistory";

export default function MotionGraph({ field }) {
  const theme = useTheme();
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);

  const motionHistory = useMemo(() => {
    return parseDeviceHistory(deviceHistory, field);
  }, [deviceHistory, field]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title="Motion History" />
      <CardContent>
        <ResponsiveContainer aspect={!isMobile ? 6 : 2}>
          <AreaChart
            data={motionHistory}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <defs>
              <linearGradient id="Temperature" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Date" type="category" />
            <YAxis
              dataKey="motion"
              type="category"
              yAxisId="1"
              scale="point"
              domain={[0, 1]}
              tickFormatter={(value) => (value === 0 ? "No" : "Yes")}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              formatter={(value) => [
                value === 0 ? "No" : "Yes",
                "Motion Detected",
              ]}
            />
            <Area
              yAxisId="1"
              type="monotone"
              dataKey="motion"
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#Temperature)"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
