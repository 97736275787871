import { configureStore } from "@reduxjs/toolkit";
import devicesReducer from "./reducers/devices";
import organisationsReducer from "./reducers/organisations";
import snackbarReducer from "./reducers/snackbar";
import isMobileReducer from "./reducers/isMobile";
import themeModeReducer from "./reducers/themeMode";
import isFullscreenReducer from "./reducers/isFullscreen";
import actionsReducer from "./reducers/actions";
import basesReducer from "./reducers/bases";
import contactsReducer from "./reducers/contacts";
import geofencesReducer from "./reducers/geofences";
import searchReducer from "./reducers/search";
import userReducer from "./reducers/user";
import deviceReducer from "./reducers/device";
import selectedOrganisationReducer from "./reducers/selectedOrganisation";
import dateRangeReducer from "./reducers/dateRange";
import deviceHistoryReducer from "./reducers/deviceHistory";
import currentReducer from "./reducers/current";
import pitchReducer from "./reducers/pitch";
import deviceAlertsReducer from "./reducers/deviceAlerts";
import deviceProfileReducer from "./reducers/deviceProfile";
import organisationLogsReducer from "./reducers/organisationLogs";
import userOrganisationRolesReducer from "./reducers/userOrganisationRoles";
import dashboardsReducer from "./reducers/dashboards";
import reportsReducer from "./reducers/reports";
import assetsReducer from "./reducers/assets";
import assetHistoryReducer from "./reducers/assetHistory";

export const store = configureStore({
  reducer: {
    actions: actionsReducer,
    bases: basesReducer,
    contacts: contactsReducer,
    devices: devicesReducer,
    geofences: geofencesReducer,
    isFullscreen: isFullscreenReducer,
    isMobile: isMobileReducer,
    organisations: organisationsReducer,
    organisationLogs: organisationLogsReducer,
    search: searchReducer,
    selectedOrganisation: selectedOrganisationReducer,
    snackbar: snackbarReducer,
    themeMode: themeModeReducer,
    user: userReducer,
    device: deviceReducer,
    dateRange: dateRangeReducer,
    deviceHistory: deviceHistoryReducer,
    current: currentReducer,
    pitch: pitchReducer,
    deviceAlerts: deviceAlertsReducer,
    deviceProfile: deviceProfileReducer,
    userOrganisationRoles: userOrganisationRolesReducer,
    dashboards: dashboardsReducer,
    reports: reportsReducer,
    assets: assetsReducer,
    assetHistory: assetHistoryReducer,
  },
});
