import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
//import { useSelector } from "react-redux";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";
import fetchData from "../../API/Fetch";
import WidgetCard from "./WidgetCard";
import moment from "moment";

export default function GraphWidget(props) {
  //const devices = useSelector((state) => state.devices);
  const { widget, isShare, editMode, dashboardId, refreshDashboard } = props;
  const theme = useTheme();

  const [title, setTitle] = React.useState("");
  const [values, setValues] = React.useState({});
  const [history, setHistory] = React.useState({});
  // const [date, setDate] = React.useState("N/A");

  useEffect(() => {
    async function fetchValues() {
      let daysOfData = 1;
      if (widget && widget.field) {
        let field = JSON.parse(widget.field);
        setTitle(field["Title"]);

        //valeFields is an array of values in the format deviceid,field
        let valueFields = field["Values"];

        //fetch each device, get a history of each for 24 hours, and then add that value to the graph
        let history = [];

        //create a unixtimestamp for the startDate and endDate
        let endDate = Math.floor(Date.now() / 1000);
        let startDate = endDate - 60 * 60 * 24 * daysOfData;

        valueFields.forEach(async (valueField) => {
          let [deviceId, field] = valueField.split(",");

          //get todays

          //now get the device history, get the data field, fetch the value for each history item.
          let deviceHistory = await fetchData(
            `/frame/${deviceId}/${startDate}/${endDate}`
          );

          //we now want to extract the data field from each history item and the date
          if (deviceHistory.length > 0) {
            let data = deviceHistory.map((historyItem) => {
              let data = JSON.parse(historyItem.data);
              //date is in format "2024-08-11T13:39:11.562195" set the seconds and milliseconds to 0

              let date = new Date(historyItem.time_created);
              date.setSeconds(0);
              date.setMilliseconds(0);

              //format the date back to 2024-08-11T13:39:11.562195
              date = date.toISOString();

              return {
                Date: date,
                [field]: data[field] || 0,
              };
            });

            history = history.concat(data);
            //sort the history by date, newest last
            history.sort((a, b) => {
              return new Date(a.Date) - new Date(b.Date);
            });
            setHistory(history);
          }
        });

        let value = valueFields.map((valueField) => valueField.split(",")[1]);

        setValues(value);
      }
    }
    fetchValues();
  }, [widget]);

  return (
    <WidgetCard
      widget={widget}
      refreshDashboard={refreshDashboard}
      editMode={editMode}
      isShare={isShare}
      dashboardId={dashboardId}
      title={title}
      date={moment().toISOString()}
    >
      {
        <ResponsiveContainer>
          <AreaChart data={history}>
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              <linearGradient id="Temperature" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
              //make values smaller to read
              tick={{ fontSize: 10 }}
            />
            {
              //add a y axis for each value
            }
            {values &&
              values.length &&
              values.map((value) => {
                return (
                  <YAxis
                    key={value}
                    dataKey={value}
                    type={
                      history && history.length > 0
                        ? isNaN(history[0][value])
                          ? "category"
                          : "number"
                        : "number"
                    }
                    domain={["auto", "auto"]}
                    //make values smaller to read
                    tick={{ fontSize: 10 }}
                  />
                );
              })}

            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
            />

            {values &&
              values.length &&
              values.map((value, index) => {
                return (
                  <Area
                    key={value}
                    type="monotone"
                    dataKey={value}
                    stroke={theme.palette.primary.main}
                    fillOpacity={1}
                    fill="url(#Temperature)"
                  />
                );
              })}
          </AreaChart>
        </ResponsiveContainer>
      }
    </WidgetCard>
  );
}
