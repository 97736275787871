import React from "react";
import { Route, Routes } from "react-router-dom";
import Devices from "./containers/Devices";
import Account from "./containers/Account";
import Device from "./containers/Device";
import Contacts from "./containers/Contacts";
import Geofences from "./containers/Geofences";
import GeofenceAdd from "./containers/GeofenceAdd";
import GeofenceEdit from "./containers/GeofenceEdit";
import Share from "./containers/Share";
import AuthError from "./containers/AuthError";
import Actions from "./containers/Actions";
import Organisations from "./containers/Organisations";
import OrganisationMembers from "./containers/OrganisationMembers";
import LimitReached from "./containers/LimitReached";
import RegisterCard from "./containers/RegisterCard";
import PaymentSuccess from "./containers/PaymentSuccess";
import SubscriptionSuccess from "./containers/SubscriptionSuccess";
import NotFound from "./containers/NotFound";
import ActionFlow from "./containers/ActionFlow";
import ActionLogs from "./containers/ActionLogs";
import CommandInterface from "./containers/CommandBuilder";
import OrganisationLogs from "./containers/OrganisationLogs";
import Dashboards from "./containers/Dashboards";
import Dashboard from "./containers/Dashboard";
import DashboardSharePage from "./containers/DashboardShare";
import Reports from "./containers/Reports";
import Report from "./containers/Report";
import Assets from "./containers/Assets";
import Asset from "./containers/Asset";

export default function AppRoutes(props) {
  return (
    <Routes>
      <Route path="/" exact element={<Devices />} />
      <Route
        path="/account"
        exact
        element={<Account handleThemeChange={props.handleThemeChange} />}
      />
      <Route
        path={"/device"}
        element={<Devices />}
        collapsed={props.collapsed}
      />
      <Route
        path={"/device/:id"}
        element={<Device collapsed={props.collapsed} />}
      />
      <Route path="/contact" element={<Contacts />} />
      <Route path="/geofence" element={<Geofences />} />
      <Route path="/geofence/add" element={<GeofenceAdd />} />
      <Route path="/geofence/:id" element={<GeofenceEdit />} />
      <Route path="/share/:id" element={<Share />} />
      <Route
        path="/share/dashboard/:id"
        element={
          <DashboardSharePage handleThemeChange={props.handleThemeChange} />
        }
      />
      <Route path="/auth-error" element={<AuthError />} />
      <Route path="/action" element={<Actions />} />
      <Route path="/action/:id/logs" element={<ActionLogs />} />
      <Route path="/organisation" element={<Organisations />} />
      <Route
        path="/organisation/:id/members"
        element={<OrganisationMembers />}
      />
      <Route path="/organisation/:id/logs" element={<OrganisationLogs />} />
      <Route path="/billing/limit-reached" element={<LimitReached />} />
      <Route path="/billing/register-card" element={<RegisterCard />} />
      <Route path="/billing/success" element={<PaymentSuccess />} />
      <Route
        path="/billing/subscription-success"
        element={<SubscriptionSuccess />}
      />
      <Route path="actionflow" element={<ActionFlow />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/command" element={<CommandInterface />} />
      <Route path="/dashboard" element={<Dashboards />} />
      <Route
        path="/dashboard/:id"
        element={<Dashboard collapsed={props.collapsed} />}
      />
      <Route path="/report" element={<Reports />} />
      <Route path="/report/:id" element={<Report />} />
      <Route path="/asset" element={<Assets />} />
      <Route path="/asset/:id" element={<Asset />} />
    </Routes>
  );
}
