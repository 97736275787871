import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import addWidget from "../../Functions/addWidget";
import { IconPicker } from "react-fa-icon-picker";
import { widgets } from "../Widgets/widgets";

export default function AddWidgetDialog(props) {
  const { open, handleClose, devices, dashboardId, dashboardWidgets } = props;
  const { t } = useTranslation("common");
  const [fieldValues, setFieldValues] = useState({});

  const [widget, setWidget] = useState({});
  const [fields, setFields] = useState([]);
  const [keys, setKeys] = useState([]);
  const [devicesAndFields, setDevicesAndFields] = useState([]);

  const handleAdd = () => {
    if (widget === "") {
      return;
    }

    //we need to check if the widget has all the fields specified in widgets, any missing fields, set them to the default value
    let missingFields = [];
    fields.forEach((field) => {
      if (!fieldValues[field.name]) {
        missingFields.push(field.name);
      }
    });

    if (missingFields.length) {
      //set a default value for the missing fields
      missingFields.forEach((field) => {
        setFieldValues({
          ...fieldValues,
          [field]: "",
        });
      });
    }

    addWidget(widget, fieldValues, dashboardId, dashboardWidgets);
    updateSnackbarMessage("Widget added");

    handleClose();
  };

  useEffect(() => {
    if (widget) {
      const selectedWidget = widgets.find((w) => w.id === widget);
      if (selectedWidget && selectedWidget.fields) {
        setFields(selectedWidget.fields);
      }
    }

    if (devices.length === 0) {
      return;
    }
    if (devices && devices.length) {
      //get the devices and fields
      let devicesAndFields = [];
      devices.forEach((device) => {
        if (device.last_data !== null) {
          try {
            JSON.parse(device.last_data);
          } catch (error) {
            return;
          }

          let keys = Object.keys(JSON.parse(device.last_data));
          keys.forEach((key) => {
            devicesAndFields.push({
              id: device.id + "," + key,
              name: device.name + " - " + key,
            });
          });
        }
      });

      setDevicesAndFields(devicesAndFields);
    }
  }, [widget, devices]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>{t("dashboards.add_widget")}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            select
            label={t("dashboards.widget_type")}
            value={widget}
            onChange={(event) => setWidget(event.target.value)}
            variant="outlined"
            fullWidth
            sx={{ my: 2 }}
          >
            {widgets &&
              widgets.length &&
              widgets.map((widget) => (
                <MenuItem key={widget.id} value={widget.id}>
                  {widget.name}
                </MenuItem>
              ))}
          </TextField>
        </FormControl>
        {widget && fields && fields.length
          ? fields.map((field) =>
              //if field name is value, we need to show a select box with the devices and then one for the field
              field.type === "selectedDevices" ? (
                <Box>
                  {devices &&
                    devices.length &&
                    devices.map((device) => (
                      <Box
                        key={device.id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mb: 2,
                        }}

                        //we want to show the device name and a toggle switch
                      >
                        <Typography>{device.name}</Typography>
                        <Switch
                          checked={
                            fieldValues["selectedDevices"] &&
                            fieldValues["selectedDevices"].length &&
                            fieldValues["selectedDevices"].includes(
                              device.id + ",true"
                            )
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            //update the selected devices to be true or false
                            let selectedDevices =
                              fieldValues["selectedDevices"];
                            if (e.target.checked) {
                              //check to see if the device is already in the array, if it is update it to true
                              if (
                                selectedDevices &&
                                selectedDevices.length &&
                                selectedDevices.includes(device.id + ",false")
                              ) {
                                selectedDevices = selectedDevices.filter(
                                  (d) => d !== device.id + ",false"
                                );
                              }
                              selectedDevices.push(device.id + ",true");
                            } else {
                              //check to see if the device is already in the array, if it is update it to false
                              if (
                                selectedDevices &&
                                selectedDevices.length &&
                                selectedDevices.includes(device.id + ",true")
                              ) {
                                selectedDevices = selectedDevices.filter(
                                  (d) => d !== device.id + ",true"
                                );
                              }
                              selectedDevices.push(device.id + ",false");
                            }
                            setFieldValues({
                              ...fieldValues,
                              selectedDevices: selectedDevices,
                            });
                          }}
                        />
                      </Box>
                    ))}
                </Box>
              ) : field.type === "Value" ? (
                <>
                  <TextField
                    select
                    label={"Device"}
                    value={fieldValues["device"]}
                    onChange={(e) => {
                      //get the device
                      const device = devices.find(
                        (device) =>
                          parseInt(device.id) === parseInt(e.target.value)
                      );

                      //json parse last_data, get the keys, and set it to state
                      const keys = Object.keys(JSON.parse(device.last_data));
                      setKeys(keys);

                      setFieldValues({
                        ...fieldValues,
                        [field.name]: e.target.value,
                      });
                    }}
                    sx={{ mb: 2 }}
                    variant="outlined"
                    fullWidth
                  >
                    {devices &&
                      devices.length &&
                      devices.map((device) => (
                        <MenuItem key={device.id} value={device.id}>
                          {device.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  {
                    //check not widget id 6
                    widget !== 6 ? (
                      <TextField
                        select
                        label="Field"
                        onChange={(e) => {
                          setFieldValues({
                            ...fieldValues,
                            Field: e.target.value,
                          });
                        }}
                        variant="outlined"
                        fullWidth
                        sx={{ mb: 2 }}
                      >
                        {keys && keys.length
                          ? keys.map((key) => (
                              <MenuItem key={key} value={key}>
                                {key}
                              </MenuItem>
                            ))
                          : null}
                      </TextField>
                    ) : null
                  }
                </>
              ) : field.type === "Values" ? (
                //we want an autocomplete to select the values from each device available, then when added, we set the field in the array to deviceid,field in an array
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={devicesAndFields}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions
                  onChange={(event, value) => {
                    //Values is an array of deviceid,field so we need to map the values to the correct format
                    let values = value.map((v) => {
                      return v.id;
                    });

                    setFieldValues({
                      ...fieldValues,
                      Values: values,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Values"
                      placeholder="Select Values"
                    />
                  )}
                  sx={{ mb: 2 }}
                />
              ) : field.type === "Formatter" ? (
                //show a select for a drop down, round, temperature, none
                <TextField
                  select
                  label="Formatter"
                  onChange={(e) => {
                    setFieldValues({
                      ...fieldValues,
                      Formatter: e.target.value,
                    });
                  }}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="round">Round</MenuItem>
                  <MenuItem value="temperature">Temperature</MenuItem>
                  <MenuItem value="battery">Battery</MenuItem>
                  <MenuItem value="speed">Speed</MenuItem>
                </TextField>
              ) : field.type === "Icon" ? (
                <Box
                  sx={{
                    mb: 2,
                    display: "flex",
                    alignItems: "center",
                    //space before the icon picker
                    justifyContent: "center",
                  }}
                >
                  <IconPicker
                    value={fieldValues["Icon"]}
                    onChange={(icon) => {
                      setFieldValues({
                        ...fieldValues,
                        Icon: icon,
                      });
                    }}
                  />
                </Box>
              ) : field.type === "size" ? (
                <TextField
                  select
                  label={field.name}
                  value={fieldValues["Icon Size"]}
                  onChange={(e) => {
                    setFieldValues({
                      ...fieldValues,
                      [field.name]: e.target.value,
                    });
                  }}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  <MenuItem value="1x">1x</MenuItem>
                  <MenuItem value="2x">2x</MenuItem>
                  <MenuItem value="3x">3x</MenuItem>
                  <MenuItem value="4x">4x</MenuItem>
                  <MenuItem value="5x">5x</MenuItem>
                  <MenuItem value="6x">6x</MenuItem>
                  <MenuItem value="7x">7x</MenuItem>
                  <MenuItem value="8x">8x</MenuItem>
                  <MenuItem value="9x">9x</MenuItem>
                  <MenuItem value="10x">10x</MenuItem>
                </TextField>
              ) : field.type === "mapSelect" ? (
                <TextField
                  select
                  label={field.name}
                  value={fieldValues["MapType"]}
                  onChange={(e) => {
                    setFieldValues({
                      ...fieldValues,
                      [field.name]: e.target.value,
                    });
                  }}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  <MenuItem value="roadmap">Roadmap</MenuItem>
                  <MenuItem value="satellite">Satellite</MenuItem>
                </TextField>
              ) : field.type === "downlink" ? (
                <>
                  {
                    //two fields, command and port
                  }
                  <TextField
                    label={"Command for " + field.name}
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        [field.name]: e.target.value,
                      });
                    }}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Port"
                    onChange={(e) => {
                      setFieldValues({
                        ...fieldValues,
                        [field.name + "port"]: e.target.value,
                      });
                    }}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                </>
              ) : (
                <TextField
                  key={field.id}
                  margin="dense"
                  id={field.name}
                  label={field.name}
                  type={field.type}
                  fullWidth
                  onChange={(e) => {
                    setFieldValues({
                      ...fieldValues,
                      [field.name]: e.target.value,
                    });
                  }}
                  sx={{ mb: 2 }}
                ></TextField>
              )
            )
          : null}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          {t("common.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleAdd}>
          {t("common.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
