import deleteData from "../API/Delete";
import { store } from "../store";

export default async function deleteAsset(assetId) {
  const response = deleteData(`/asset/${assetId}`);

  store.dispatch({
    type: "assets/remove",
    payload: assetId,
  });

  return response;
}
