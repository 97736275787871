import React, { useEffect } from "react";
import { Typography } from "@mui/material";

import WidgetCard from "./WidgetCard";
import { FluidGauge } from "./FluidGuage";

export default function FluidLevelWidget(props) {
  const { widget, dashboardId, refreshDashboard, editMode, isShare, devices } =
    props;
  const [percentFull, setPercentFull] = React.useState(0);
  const [title, setTitle] = React.useState("Fluid Level");
  const [distance, setDistance] = React.useState(0);
  const [date, setDate] = React.useState(new Date());

  const [width, setWidth] = React.useState(300);
  const [height, setHeight] = React.useState(200);

  useEffect(() => {
    if (!widget || !widget.field) {
      return;
    }

    let field = {};
    let deviceId = 0;
    let device = {};

    if (widget.field) {
      //decode the field object
      field = JSON.parse(widget.field);
      deviceId = field["Value Field"];

      let size = field["Size"];

      if (size) {
        setWidth(size * 30);
        setHeight(size * 30);
      }

      device = devices.find(
        (device) => parseInt(device.id) === parseInt(deviceId)
      );
    }

    if (device && device.id) {
      //get data
      let jsonData = device.last_data;
      //parse json data
      jsonData = JSON.parse(jsonData);

      let distance = 0;

      let empty = 50;
      let full = 100;

      if (field["Empty"]) {
        empty = field["Empty"];
      }

      if (field["Full"]) {
        full = field["Full"];
      }

      if (field["Field"]) {
        distance = jsonData[field["Field"]];
      }

      setDistance(distance);

      setTitle(field["Title"]);
      setDate(device.time_updated ? device.time_updated : new Date());

      //based on the distance, with full being 100% and empty being 0% calculate the percentage
      let percent = ((distance - empty) / (full - empty)) * 100;

      //if its lower than 0, set it to 0
      //if its higher than 100, set it to 100
      percent = Math.max(0, percent);
      percent = Math.min(100, percent);

      setPercentFull(percent);
    }
  }, [widget, devices]);

  return (
    <WidgetCard
      widget={widget}
      refreshDashboard={refreshDashboard}
      editMode={editMode}
      isShare={isShare}
      dashboardId={dashboardId}
      title={title}
      date={date}
    >
      <Typography variant="h4">{distance}mm</Typography>
      <FluidGauge value={percentFull} width={width} height={height} />
    </WidgetCard>
  );
}
