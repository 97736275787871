import React, { useEffect, useState } from "react";
import { formatDate } from "../../Functions/formatDate";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import AlertLogDialog from "../../components/Dialogs/AlertLog";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DeviceInfoBatteryLevel from "../DeviceInfoBatteryLevel";

export default function DeviceInfo(props) {
  const [alertLogDialogOpen, setAlertLogDialogOpen] = useState(false);
  const { t } = useTranslation("common");

  const isMobile = useSelector((state) => state.isMobile);
  const deviceAlerts = useSelector((state) => state.deviceAlerts);

  const device = useSelector((state) => state.device);
  const [hidePanel, setHidePanel] = useState(false);

  const [lastAlert, setLastAlert] = useState("");

  const toggleHidePanel = () => {
    //use local storage
    if (localStorage.getItem("hidePanel") === "true") {
      localStorage.setItem("hidePanel", "false");
      setHidePanel(false);
    } else {
      localStorage.setItem("hidePanel", "true");
      setHidePanel(true);
    }
  };

  function toggleAlertLogDialogOpen() {
    setAlertLogDialogOpen(!alertLogDialogOpen);
  }

  useEffect(() => {
    if (deviceAlerts && deviceAlerts.length > 0) {
      setLastAlert(deviceAlerts[0]);
    }
  }, [deviceAlerts]);

  useEffect(() => {
    if (localStorage.getItem("hidePanel") === "true") {
      setHidePanel(true);
    } else {
      setHidePanel(false);
    }
  }, []);

  return (
    <>
      <div
        className={
          isMobile ? "last-checkin-panel-mobile" : "last-checkin-panel"
        }
      >
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                style={{ padding: "10px" }}
                title={t("devices.current_status")}
                titleTypographyProps={{ variant: "h6" }}
                action={
                  <IconButton
                    onClick={() => {
                      toggleHidePanel();
                    }}
                  >
                    {hidePanel ? <ArrowDownward /> : <ArrowUpward />}
                  </IconButton>
                }
              />
              {hidePanel === false && (
                <CardContent
                  sx={{
                    paddingTop: 0,
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <Stack direction="column" spacing={1}>
                    <Typography
                      variant="body1"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "3",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {
                        //get last record of deviceHistory, get data, json parse it, if it has geocode then show it
                        device &&
                          device.last_data &&
                          device.last_data &&
                          JSON.parse(device.last_data).geocode && (
                            <>{JSON.parse(device.last_data).geocode}</>
                          )
                      }
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <Typography variant="body1" sx={{ flexGrow: 1 }}>
                        <strong>{t("common.last_updated")}:</strong>
                      </Typography>
                      <Typography variant="body1">
                        {device.time_updated
                          ? formatDate(
                              device.time_updated,
                              "HH:mm:ss MMMM DD YYYY"
                            )
                          : t("common.no_data")}
                      </Typography>
                    </Box>
                    <DeviceInfoBatteryLevel device={device} />
                    <Box sx={{ display: "flex" }}>
                      <Typography variant="body1" sx={{ flexGrow: 1 }}>
                        <strong>{t("devices.last_alert")}:</strong>
                      </Typography>
                      {lastAlert && lastAlert.message ? (
                        <Tooltip
                          title={lastAlert.message}
                          onClick={() => {
                            setAlertLogDialogOpen(true);
                          }}
                        >
                          <Typography
                            color="primary"
                            className="cursor-pointer"
                          >
                            {formatDate(lastAlert.time_created)}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography>{t("common.none")}.</Typography>
                      )}
                    </Box>
                    {
                      //if deviceHistory is not empty, get data, json parse it, if it has event_status !== "" then show it
                      device &&
                        device.last_data &&
                        JSON.parse(device.last_data).event_status !==
                          undefined && (
                          <Box sx={{ display: "flex" }}>
                            <Typography variant="body1" sx={{ flexGrow: 1 }}>
                              <strong>{t("devices.event_status")}:</strong>
                            </Typography>
                            <Typography>
                              {JSON.parse(device.last_data).event_status ||
                                "No Event."}
                            </Typography>
                          </Box>
                        )
                    }
                    {
                      //if deviceHistory is not empty, get data, json parse it, if it has event !== "" then show it
                      device &&
                        device.last_data &&
                        JSON.parse(device.last_data).event !== undefined && (
                          <Box sx={{ display: "flex" }}>
                            <Typography variant="body1" sx={{ flexGrow: 1 }}>
                              <strong>{t("devices.event_status")}:</strong>
                            </Typography>
                            <Typography>
                              {JSON.parse(device.last_data).event ||
                                "No Event."}
                            </Typography>
                          </Box>
                        )
                    }
                    {
                      //if deviceHistory is not empty, get data, json parse it, if it has isLost true or false then show it
                      device &&
                        device.last_data &&
                        JSON.parse(device.last_data).is_lost !== undefined && (
                          <Box sx={{ display: "flex" }}>
                            <Typography variant="body1" sx={{ flexGrow: 1 }}>
                              <strong>{t("devices.is_lost")}:</strong>
                            </Typography>
                            <Typography>
                              {JSON.parse(device.last_data).isLost
                                ? t("common.yes")
                                : t("common.no")}
                            </Typography>
                          </Box>
                        )
                    }
                  </Stack>
                </CardContent>
              )}
            </Card>
          </Grid>
        </Grid>
      </div>

      {
        // Alert Log Dialog
      }
      <AlertLogDialog
        open={alertLogDialogOpen}
        handleClose={() => toggleAlertLogDialogOpen()}
        lastAlert={lastAlert}
      />
    </>
  );
}
