import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import fetchAssets from "../../Functions/fetchAssets";
import { useSelector } from "react-redux";
import deleteAsset from "../../Functions/deleteAsset";

export default function AssetDeleteDialog(props) {
  const { open, handleClose, assetId, asset } = props;

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const handleDelete = () => {
    deleteAsset(assetId);

    updateSnackbarMessage("Asset Deleted", "success");
    handleClose();

    //set timeout 1 second to refresh the assets
    setTimeout(() => {
      fetchAssets(selectedOrganisation.id);
    }, 1000);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        Confirm Deletion Of Asset: {asset && asset.name ? asset.name : "Asset"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this Asset?
          <br />
          <br /> NOTE: It cannot be restored after deletion.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
