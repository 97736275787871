import { Box, Tooltip } from "@mui/material";
import WidgetCard from "./WidgetCard";

export default function BooleanWidget(props) {
  const { widget, dashboardId, refreshDashboard, isShare, editMode, devices } =
    props;
  let field = {};
  let value = false;
  let deviceId = 0;
  let title = "Boolean";
  let date = "";
  let iconSize = "1x";

  let trueColor = "green";
  let falseColor = "red";

  if (widget.field) {
    //decode the field object
    field = JSON.parse(widget.field);
    deviceId = field["Boolean Field"];

    let valueField = field["Field"];
    title = field["Title"];

    if (field["True Color"]) {
      trueColor = field["True Color"];
    }

    if (field["False Color"]) {
      falseColor = field["False Color"];
    }

    if (field["Icon Size"]) {
      iconSize = field["Icon Size"];
    }

    if (iconSize === "1x") {
      iconSize = "24px";
    } else if (iconSize === "2x") {
      iconSize = "48px";
    } else if (iconSize === "3x") {
      iconSize = "72px";
    } else if (iconSize === "4x") {
      iconSize = "96px";
    } else if (iconSize === "5x") {
      iconSize = "120px";
    } else if (iconSize === "6x") {
      iconSize = "144px";
    } else if (iconSize === "7x") {
      iconSize = "168px";
    } else if (iconSize === "8x") {
      iconSize = "192px";
    } else if (iconSize === "9x") {
      iconSize = "216px";
    } else if (iconSize === "10x") {
      iconSize = "240px";
    }

    let device = devices.find(
      (device) => parseInt(device.id) === parseInt(deviceId)
    );

    if (device) {
      let data = JSON.parse(device.last_data);
      date = device.time_updated;

      if (data) {
        value = data[valueField];
      }
    }
  }

  return (
    <WidgetCard
      widget={widget}
      refreshDashboard={refreshDashboard}
      editMode={editMode}
      isShare={isShare}
      dashboardId={dashboardId}
      title={title}
      date={date}
    >
      <Box
        sx={{
          //flex, full height, and center the content vertically and horizontally
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Tooltip title={value ? "True" : "False"} placement="top" arrow>
          <Box
            sx={{
              width: iconSize,
              height: iconSize,
              borderRadius: "50%",
              backgroundColor: value ? trueColor : falseColor,
            }}
          ></Box>
        </Tooltip>
      </Box>
    </WidgetCard>
  );
}
