export const CONFIG = {
  API_URL: "https://api.irisflextrack.com",
  mapboxAccessToken:
    "pk.eyJ1IjoiYmZnbmVpbCIsImEiOiJjbGR2azExeW4wZ28yM29tZm4xZjJwdHFxIn0.Mdv--BkZwKSw-pdTRMQdPg",
  mapStyle: "mapbox://styles/bfgneil/cle2sqoh8003s01ogafzo3xsl",
  siteTitle: "Iris IoT Portal",
  siteTitleDescription: "",
  battery_minMV: 2300,
  battery_maxMV: 3000,
  shareUrl: "https://irisiotportal.co.uk/share/",
  dashboardShareUrl: "https://irisiotportal.co.uk/share/dashboard/",
  websiteURL: "https://irisiotportal.co.uk",
  Auth0Domain: "irisflextrack.uk.auth0.com",
  Auth0ClientId: "ccycbxfiE6rUhSVBjPVf3MmbOWpEeIDj",
  Auth0Audience: "https://api.irisflextrack.com",
  primaryColour: "#24b3bb",
  primaryColourRGB: {
    r: 36,
    g: 179,
    b: 187,
  },
  secondaryColour: "#224044",
  defaultTheme: "light",
  defaultLanguage: "en",
  locale: "en",
  organisation_enabled: true,
  billing_enabled: false,
  manual_add: false,
  self_host: false,
  feedback_enabled: false,
  feedback_url: "",
  show_geofences: false,
  api_key_enabled: false,
  register_card_enabled: false,
  defaultDateFormat: "DD/MM/YYYY",
  show_buy_link: false,
  buy_link: "https://hexaspot.com/collections/trackpac",
};
